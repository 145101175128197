import React, { useEffect } from 'react';

import Layout from '../components/layout';
import EmbedVideo from '../components/EmbedVideo';
import SEO from '../components/seo';

const Schedule = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    document.body.appendChild(script);
  }, []);

  return (
    <Layout>
      <SEO title="Schedule Your Call" />

      <div className="flex flex-col justify-center min-h-screen min-w-screen py-4">
        <div className="mx-auto p-4 w-full sm:max-w-md md:max-w-xl xl:max-w-2xl 2xl:max-w-4xl">
          <div className="bg-red mb-16 mx-auto p-4 rounded-md shadow-md xl:p-8">
            <EmbedVideo
              src="https://player.vimeo.com/video/651300079?h=99b3928c53"
              title="schedule your call"
              type="Vimeo"
            />
          </div>

          <h1 className="font-Oswald mb-4 text-2xl text-center md:text-4xl">
            Schedule Your Discovery Call
          </h1>
        </div>

        <div className="max-w-6xl mx-auto w-full md:-mt-16 xl:-my-16">
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/beastlycoaching-brandon"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Schedule;
